import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import { light as lightTheme } from "./themes/light.js";

import "./style.scss";
import Header from './components/Header'
import LoadingSplash from './components/LoadingSplash'

const DEBUG = false;
const Home = lazy(() => import('./views/home'));
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname)

  useEffect(() => {
    setPathname(location.pathname)
  }, [location]);

  let themeMode = lightTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <Suspense fallback={<LoadingSplash />}>
        <div className={`'app-container'`}>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
