import { Box, Drawer } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './header.scss';
import Logo from '../../assets/images/logo.svg'
import Logo2 from '../../assets/images/logo2.svg'
import Twitter from '../../assets/images/twitter.svg'
import Discord from '../../assets/images/discord.svg'
import Telegram from '../../assets/images/telegram.svg'
import Dashboard from '../../assets/images/dashboard.svg'
import Menu from '../../assets/images/menu.svg'
import Close from '../../assets/images/close.svg'
import { useState } from "react";

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1080px)");

    const [open, setOpen] = useState(false)
    const handlerToggle = () => {
        setOpen(!open)
    }

    return (
        <Box display='flex' justifyContent='center' className='header-view'>
            {
                isSmallScreen ?
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{ height: 70, width: '100%', paddingLeft: 24, paddingRight: 32 }}>
                        <Box display='flex' alignItems='center'>
                            <a href="https://discord.gg/tTCz22HPwH" target='_blank'>
                                <img src={Discord} />
                            </a>
                            <a href="https://twitter.com/entropydao_" target='_blank'>
                                <img src={Twitter} style={{ marginLeft: 26 }} />
                            </a>
                        </Box>
                        <img src={Menu} onClick={handlerToggle} />
                    </Box>
                    :
                    <Box display='flex' justifyContent='space-between' alignItems='center' className="container header font-14">
                        <Box display='flex' className="menuList">
                            <a href="#" className="menuItem">
                                <div className="color1">NRG & ENT</div>
                            </a>
                            <a href="#" className="menuItem">
                                <div className="color1">How to Participate</div>
                            </a>
                            <a href="#" className="menuItem">
                                <div className="color1">Roadmap</div>
                            </a>
                        </Box>
                        <img src={Logo} />
                        <Box display='flex' alignItems='center'>
                            <a href="https://discord.gg/tTCz22HPwH" target='_blank'>
                                <img src={Discord} />
                            </a>
                            <a href="https://twitter.com/entropydao_" target='_blank'>
                                <img src={Twitter} className="ml-18" />
                            </a>
                            <Box display='flex' justifyContent='center' alignItems='center' className="dashboard">
                                <img src={Dashboard} />
                                <div className="ml-8">dashboard</div>
                            </Box>
                        </Box>
                    </Box>
            }
            <Drawer style={{ width: '100%' }}
                open={open}
                onClose={handlerToggle}>
                <div className="drawerBox">
                    <div className="footerContainer">
                        <Box display='flex' justifyContent='space-around' alignItems='center' className="footer">
                            <a href="https://discord.gg/tTCz22HPwH" target='_blank'>
                                <img src={Discord} />
                            </a>
                            <a href="https://twitter.com/entropydao_" target='_blank'>
                                <img src={Twitter} />
                            </a>
                            <a href="" target='_blank'>
                                <img src={Telegram} />
                            </a>
                            <div className="line"></div>
                            <div className="circle"></div>
                        </Box>
                    </div>
                    <Box display='flex' justifyContent='space-between' alignItems='center' className="drawerHeader">
                        <img src={Logo2} />
                        <img src={Close} onClick={handlerToggle} />
                    </Box>
                    <div className="menuList-m font-36 font-weight-b">
                        <a href="#">
                            <div className="menuItem-m mt-30 color1">NRG & ENT</div>
                        </a>
                        <a href="#">
                            <div className="menuItem-m mt-30 color1">How to Participate</div>
                        </a>
                        <a href="#">
                            <div className="menuItem-m mt-30 color1">Roadmap</div>
                        </a>
                    </div>
                    <Box display='flex' justifyContent='center' alignItems='center' className="dashboard mt-30">
                        <img src={Dashboard} />
                        <div className="ml-8 font-14">dashboard</div>
                    </Box>
                </div>
            </Drawer>
        </Box>
    )
}